<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <base-material-card icon="mdi-file-document-box-multiple-outline">
          <studios-form
            :studio="studio"
            @validated="saveStudio()"
          ></studios-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StudiosForm from "@/components/studio/Form";
import constants from "@/constants";
import { mapActions } from "vuex";
export default {
  name: "StudioGuest",
  components: { StudiosForm },
  data() {
    return {
      studio: {
        timetable: [[], [], [], [], [], [], []],
        user: {
          role_id: constants.roles.STUDIO_GUEST,
        },
        social_networks: {},
      },
    };
  },
  methods: {
    ...mapActions("studios", ["addStudioGuest"]),
    saveStudio() {
      this.addStudioGuest({ studio: this.studio }).then((response) => {
        if (response.success) {
          this.errors.clear();
          this.$alert(this.$t("studio_create_success"));
        } else {
          /*
          this.$store.commit(
            "app/SET_NOTIFICATION",
            { message: response, show: true, color: "error" },
            { root: true }
          );
          */
        }
        this.$router.back();
      });
    },
  },
};
</script>